import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,[_c('base-editor',{ref:"rte_main",attrs:{"height":"500"},model:{value:(_vm.new_value),callback:function ($$v) {_vm.new_value=$$v},expression:"new_value"}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VCard,{staticClass:"sticky",attrs:{"flat":""}},[_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.$store.getters.translate('search'),"flat":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline","prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VTreeview,{attrs:{"search":_vm.search,"transition":"","item-key":"name","dense":"","items":_vm.fields,"item-text":"name"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.insertContent(item)}}},[_vm._v(_vm._s(_vm.$store.getters.translate(item.name)))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }