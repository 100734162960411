<template>
  <div>
    <v-row>
      <v-col>
        <base-editor ref="rte_main" v-model="new_value" height="500"/>
      </v-col>
      <v-col cols="2">
        <v-card flat class="sticky">
          <v-card-text>
            <v-text-field
              v-model="search"
              :label="$store.getters.translate('search')"
              flat
              hide-details
              clearable
              clear-icon="mdi-close-circle-outline"
              prepend-icon="mdi-magnify"/>
            <v-treeview
              :search="search"
              transition
              item-key="name"
              dense
              :items="fields"
              item-text="name">
              <template v-slot:label="{ item }">
                <a @click="insertContent(item)">{{ $store.getters.translate(item.name) }}</a>
              </template>
            </v-treeview>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseEditor from "../../../components/commonComponents/BaseEditor";

export default {
  components: {
    BaseEditor
  },
  props: ["value", "model_type"],
  data() {
    return {
      new_value: "",
      search: "",
      key: 1,
      fields: [],
    };
  },
  mounted() {
    this.getFields();
    this.new_value = this.value;
  },
  methods: {
    insertContent(item) {
      if (item.name) {
        let model_type = item.model_type.toLowerCase() + ".";
        let source = item.source ? item.source.toLowerCase() + "." : "";
        let name = item.name.toLowerCase();
        this.$refs.rte_main.$refs.text_editor.editor.insertContent("${" + model_type + source + name + "}");
      }
    },
    async getFields() {
      // get main model fields
      this.fields = [];
      let models = [];
      if (this.$related_models[this.model_type]) {
        this.$related_models[this.model_type].forEach(($relation) => {
          models.push($relation);
        });
      }
      models.push(this.model_type);
      this.$http
        .get(this.$store.getters.appUrl + "v2/fields?model_types=" + models.join())
        .then((response) => {
          this.fields = this.$lodash
            .chain(response.data)
            .groupBy("model_type")
            .map((value, key) => ({
              name: this.$store.getters.translate(key.toLowerCase()),
              children: value,
            }))
            .value();
          this.fields = this.$lodash.sortBy(this.fields, "name");
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
    },
  },
  watch: {
    new_value: {
      handler() {
        this.$emit("input", this.new_value);
      },
    },
  },
};
</script>
